import mixinsInput from '/@mixins/input'
import get from 'lodash.get'

export default {
  mixins: [mixinsInput],
  data() {
    return {
      selected: '',
    }
  },
  computed: {
    label() {
      return get(this.settings, 'label', 'label')
    },
    trackBy() {
      return get(this.settings, 'trackBy', 'key')
    },
    isPlaceHolderVisible() {
      return get(this.settings, 'displayPlaceholder', false)
    },
    placeHolder() {
      return get(this.settings, 'placeHolder', 'select')
    },
    isObjectArray() {
      if (this.options && typeof this.options[0] === 'object') {
        return true
      }

      return false
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.selected = this.value ? this.value : ''
      },
    },
    selected: {
      handler() {
        this.emitValue(this.selected)
      },
    },
  },
  mounted() {
    if (this.settings?.valueFormatters) {
      this.settings.valueFormatters().forEach((formatter) => {
        this.selected = formatter(this.value)
      })
    }
  }
}
