<template>
  <div class="field">
    <label>
      <span>
        <slot></slot>
      </span>
      <div class="inputs">
        <input type="checkbox" v-model="isActive" />
      </div>
    </label>
    <template v-if="isActive">
      <div class="field">
        <label>
          <span>range min</span>
          <div class="inputs">
            <input type="number" placeholder="min" v-model.number="min" />
          </div>
        </label>
      </div>
      <div class="field">
        <label>
          <span>range max</span>
          <div class="inputs">
            <input type="number" placeholder="max" v-model.number="max" />
          </div>
        </label>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

type SettingsType = {
  isActive: boolean
  min: number
  max: number
}

const emit = defineEmits<{
  (
    event: 'setting',
    obj: {
      attribute: string
      type: string
      value: boolean | number
    },
  ): void
}>()

const props = defineProps<{
  settings: SettingsType
  type: string
}>()

const isActive = computed({
  get: () => props.settings.isActive,
  set: (value) => emitSetings('isActive', value),
})

const min = computed({
  get: () => props.settings.min,
  set: (value) => emitSetings('min', value),
})

const max = computed({
  get: () => props.settings.max,
  set: (value) => emitSetings('max', value),
})

const emitSetings = (type: string, value: boolean | number) => {
  emit('setting', {
    attribute: props.type,
    type,
    value,
  })
}
</script>
