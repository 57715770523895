import Vue from 'vue'
import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'

Vue.directive('can', function(el, bindings, vnode) {
  const isAllowed = vuexStore.getters[types.SETTINGS_STATE].permissions.includes(bindings.arg)
  if (!isAllowed) {
    const comment = document.createComment(' ')

    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined,
    })

    vnode.text = ' '
    vnode.elm = comment
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
    vnode.data.directives = undefined

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el)
    }
  }
})
