import { mixin as clickaway } from 'vue-clickaway'
import { colorScheme } from '/@shared/constants'
import mixinInput from '/@mixins/input'

export default {
  props: {
    value: {
      type: String,
      default: '#FFFFFF',
    },
    type: {
      type: String,
      default: '',
    },
    unitDimension: {
      type: Number,
      default: 24,
    },
    unitAmountHorizontal: {
      type: Number,
      default: 5,
    },
  },
  mixins: [clickaway, mixinInput],
  data() {
    return {
      colors: colorScheme,
      isOpen: false,
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
    select(key) {
      this.emitValue(this.colors[key])
      this.close()
    },
    clickAway() {
      if (this.isOpen) {
        this.close()
      }
    },
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.emitValue(value)
      },
    },
    unitSelectedStyleObject() {
      return {
        width: this.unitDimension + 'px',
        height: this.unitDimension + 'px',
        backgroundColor: this.value,
      }
    },
    unitStyleObject() {
      return {
        width: this.unitDimension + 'px',
        height: this.unitDimension + 'px',
      }
    },
  },
}
