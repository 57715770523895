import mixinMotorcortex from '/@mixins/motorcortex'
import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'
import BaseSubscription from '/@plugins/communication/subscriptions/base'
import SubscriptionData from '/@/plugins/communication/subscriptions/data'
import { formatControllerTime } from '/@shared/utils'

export default {
  mixins: [mixinMotorcortex],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logs: [],
    }
  },
  computed: {
    ...mapStoreGetters(storeTypes.SETTINGS, ['log.paths.subscription']),
    hasLogs() {
      return this.logs.length > 0
    },
  },
  watch: {
    isVisible() {
      this.scrollToBottom()
    },
    async logs() {
      const containerHeight = this.$el.getBoundingClientRect().height
      const elementHeight = this.$el.scrollHeight
      const isUserNotReadingLogs = Math.abs(this.$el.scrollTop - (elementHeight - containerHeight)) < 1

      if (isUserNotReadingLogs) {
        await this.$nextTick()
        this.scrollToBottom()
      }
    },
  },
  methods: {
    init() {
      const subscriptionData = new SubscriptionData(this.$communication, [this.logPathsSubscription], 'grid_logs', 1)
      this.subscription = new BaseSubscription(this.$communication, this.subscriptionCallback, subscriptionData)
    },
    subscriptionCallback(messages) {
      let logsLocal = this.logs

      messages.forEach((logItem) => {
        logsLocal.push(logItem.value)
      })

      logsLocal = logsLocal.slice(-500)
      this.logs = logsLocal
    },
    scrollToBottom() {
      this.$el.scrollTop = this.$el.scrollHeight
    },
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.subscription.destroy()
  },
}
