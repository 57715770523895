import mixinInput from '/@mixins/input'
import { settingTypes } from '/@shared/constants'
import { empty } from '/@shared/utils'

const inputTypes = {
  VALUE: 'value',
  PATH: 'path',
}

export default {
  mixins: [mixinInput],
  data: () => {
    return {
      inputOptions: [
        {
          title: 'static value',
          value: inputTypes.VALUE,
        },
        {
          title: 'path',
          value: inputTypes.PATH,
        },
      ],
    }
  },
  computed: {
    path() {
      return this.settings.path
    },
    hasPath() {
      return !empty(this.path)
    },
    hasColor() {
      return this.settings.hasOwnProperty('color')
    },
    isInputTypeValue() {
      return this.inputType === inputTypes.VALUE
    },
    isInputTypePath() {
      return this.inputType === inputTypes.PATH
    },
    breakValue: {
      get() {
        return this.value
      },
      set(value) {
        this.emitValue(value)
      },
    },
    inputType: {
      get() {
        return this.settings.inputType
      },
      set(value) {
        this.emitValue(null)
        this.emitSetting(settingTypes.INPUT_TYPE, value)
      },
    },
    isLabelVisible: {
      get() {
        return this.settings.isLabelVisible
      },
      set(value) {
        this.emitSetting(settingTypes.IS_LABEL_VISIBLE, value)
      },
    },
    path: {
      get() {
        return this.value
      },
      set(value) {
        this.emitValue(value)
      },
    },
    color: {
      get() {
        return this.settings.color
      },
      set(value) {
        this.emitSetting(settingTypes.COLOR, value)
      },
    },
    isBreakValueVisible: {
      get() {
        return this.settings.isBreakValueVisible
      },
      set(value) {
        this.emitSetting(settingTypes.IS_BREAK_VALUE_VISIBLE, value)
      },
    },
  },
}
