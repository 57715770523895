import BaseSubscription from './base'
import _get from 'lodash.get'
import { errorLevelMessages } from '/@/shared/constants'
import motorcortex from '@vectioneer/motorcortex-js'
import store from '/@vuex/store'
import { types } from '/@/vuex/types'

export default class ErrorSubscription extends BaseSubscription {
  initVariables() {
    this.motorCortexTypes = new motorcortex.MessageTypes()
    this.errorListType = this.motorCortexTypes.getTypeByName('motorcortex.ErrorList')
    this.updateCounter = 0
    this.errorCodes = store.getters[types.SETTINGS_ATTRIBUTE]('error.codes')
  }

  processMessages(messages) {
    try {
      const errorList = this.errorListType.decode(messages[0].value)
      const newUpdateCounter = errorList.updateCounter

      if (newUpdateCounter !== this.updateCounter) {
        this.callback(this.formatErrorList(errorList.errors))
        this.updateCounter = newUpdateCounter
      }
    } catch (e) {
      console.error(e)
    }
  }

  formatErrorList(errorList) {
    return errorList
      .filter(error => {
        return error.timestamp !== 0
      })
      .map(error => {
        error.timestampDisplay = new Date(error.timestamp)
          .toISOString()
          .replace(/T/, ' ')
          .replace(/Z/, ' ')
          .replace('.', ':')

        error.errorLevelMessage = errorLevelMessages[error.errorLevel]
        error.message = _get(this.errorCodes, error.errorNumber, 'ERROR')

        return error
      })
  }

  reset() {
    this.updateCounter = -1
  }
}
