import mixinMotorcortex from '/@mixins/motorcortex'
import mixinSettings from '/@mixins/settings'
import _get from 'lodash.get'
import BaseSubscription from '/@plugins/communication/subscriptions/base'
import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'
import SubscriptionData from '/@/plugins/communication/subscriptions/data'
import { empty } from '/@shared/utils'

export default {
  mixins: [mixinMotorcortex, mixinSettings],
  data() {
    return {
      currentTime: '00:00:00',
      stateLabel: 'idle',
      currentTime: '',
      lastKnownControllerDate: null,
      clientDate: null,
      controllerDateSetAt: null,
    }
  },
  computed: {
    ...mapStoreGetters(storeTypes.SETTINGS, ['state.paths.subscription', 'state.frequencyDivider', 'timePath']),
    hasTimePath() {
      return !empty(this.timePath)
    },
  },
  methods: {
    init() {
      this.initState()
      this.initTime()
    },
    initState() {
      const subscriptionData = new SubscriptionData(
        this.$communication,
        [this.statePathsSubscription],
        'grid_state',
        this.stateFrequencyDivider || 1200,
      )

      this.stateSubscription = new BaseSubscription(
        this.$communication,
        this.stateSubscriptionCallback,
        subscriptionData,
      )
    },
    stateSubscriptionCallback(messages) {
      const stateCode = messages[0].value
      this.stateLabel = _get(this.settings.state.codes, stateCode, stateCode)
    },
    initTime() {
      if (this.hasTimePath) {
        this.initControllerTime()
      } else {
        this.initClientTime()
      }
    },
    initClientTime() {
      setInterval(() => {
        this.currentTime = new Date().toLocaleString(false, { hour: 'numeric', minute: 'numeric', second: 'numeric' })
      }, 1000)
    },
    initControllerTime() {
      const subscriptionData = new SubscriptionData(this.$communication, [this.timePath], 'time_state', 1200)
      this.timeSubscription = new BaseSubscription(this.$communication, this.timeSubscriptionCallback, subscriptionData)
      this.clientDate = new Date()
      this.controllerDateSetAt = new Date()
      this.lastKnownControllerDate = new Date()

      setInterval(this.setControllerTime, 1000)
    },
    timeSubscriptionCallback(messages) {
      this.lastKnownControllerDate = new Date(messages[0].timestamp * 1000)
      this.controllerDateSetAt = new Date()
    },
    setControllerTime() {
      const millisecondsSinceUpdate = new Date().getTime() - this.controllerDateSetAt.getTime()

      if (millisecondsSinceUpdate >= 0 && millisecondsSinceUpdate < 1000) {
        this.clientDate = new Date(this.lastKnownControllerDate.getTime())
        this.clientDate.setMilliseconds(this.clientDate.getMilliseconds() + millisecondsSinceUpdate)
      } else {
        this.clientDate.setSeconds(this.clientDate.getSeconds() + 1)
      }

      this.currentTime = this.clientDate.toLocaleString(false, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      })
    },
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.stateSubscription.destroy()
    this.timeSubscription?.destroy()
  },
}
