import mixinInput from '/@mixins/input'
import { uuid } from '/@shared/utils'
import fileManager from './v-filemanager/index.vue'
import { fsType } from '/@/shared/constants'

export default {
  components: {
    'v-file-manager': fileManager,
  },
  props: {
    placeholder: {
      default: '',
      type: String,
    },
    target: {
      type: String,
      default: fsType.FILE,
    },
  },
  data() {
    return {
      isValid: true,
      path: '',
    }
  },
  mixins: [mixinInput],
  watch: {
    value: {
      immediate: true,
      handler() {
        this.path = this.value
      },
    },
    path() {
      if (this.path !== this.value) {
        this.emitValue(this.path)
      }
    },
  },
  computed: {
    dialogName() {
      return `dialog-${uuid().substr(0, 10)}`
    },
  },
  methods: {
    selectItem(path) {
      this.path = path
      this.$dialog.closeModal(this.dialogName)
    },
  },
}
