<template>
  <div class="v-progress">
    <transition name="fade">
      <progress max="1" min="0" :value="value" v-if="isVisible"></progress>
    </transition>
  </div>
</template>
<style lang="less" src="./style.less">

</style>
<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'

const props = withDefaults(defineProps<{
  minimum?: number
  isDone: boolean
}>(), {
  minimum: 0.08,
})

let workTimeoutReference: any = null

const value = ref<number>(0)
const isVisible = ref<boolean>(false)

const setValue = (v: number) => {
  value.value = clamp(v, props.minimum, 1)
}

const start = () => {
  isVisible.value = true
  work()
}

const stop = () => {
  clearTimeout(workTimeoutReference)
  setTimeout(() => {
    isVisible.value = false
  }, 500);
}

const work = () => {
  workTimeoutReference = setTimeout(() => {
    increase()
    work()
  }, 50)
}

const increase = () => {
  if (value.value >= 1) {
    return
  }

  const ranges = [
    { from: 0, to: 0.2, value: 0.02 },
    { from: 0.2, to: 0.4, value: 0.01 },
    { from: 0.4, to: 0.8, value: 0.005 },
    { from: 0.8, to: 0.95, value: 0.0025 },
  ]

  const currentValue = value.value as number
  const amount = ranges.find((range) => currentValue >= range.from && currentValue <= range.to)?.value ?? 0

  return setValue(clamp(value.value + amount, 0, 0.995))
}

const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max)
}

watch(() => props.isDone, () => {
  if (props.isDone) {
    setValue(1)
    stop()
  } else {
    setValue(0)
    start()
  }
}, { immediate: true })

</script>
