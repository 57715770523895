import Sortable from 'sortablejs'

let lastTarget
let sourceID = null
let targetID = null
let els = []

function GeometryPlugin() {
  function Geometry(props) {
    const { el, options } = props

    if (options.geometry) {
      els.push(el)
    }

    this.defaults = {
      targetClass: 'point-body',
      overClass: 'over',
      dataSourceClass: 'point',
    }
  }

  function reset() {
    sourceID = null
    targetID = null
  }

  function hasCoordinates(event) {
    return event.clientX !== undefined && event.clientY !== undefined
  }

  function extractTouch(event) {
    return event.changedTouches && event.changedTouches.length ? event.changedTouches[0] : event
  }

  Geometry.prototype = {
    dragStart({ dragEl }) {
      reset()
      sourceID = dragEl.dataset.id
    },
    dragOverValid({ originalEvent }) {
      if (!hasCoordinates(originalEvent)) {
        return
      }

      let touch = extractTouch(originalEvent)
      const target = document.elementFromPoint(touch.clientX, touch.clientY)
      const options = this.options

      if (target) {
        const targetBody = target.closest(`.${options.targetClass}`)
        const point = target.closest(`.${options.dataSourceClass}`)

        if (point) {
          targetID = point.dataset.id
        }

        if (targetBody !== lastTarget) {
          Sortable.utils.toggleClass(lastTarget, options.overClass, false)
        }

        Sortable.utils.toggleClass(targetBody, options.overClass, true)
        lastTarget = targetBody
      }
    },
    drop({ originalEvent }) {
      Sortable.utils.toggleClass(lastTarget, this.options.overClass, false)

      if (!originalEvent) {
        return
      }

      let touch = extractTouch(originalEvent)
      let target = document.elementFromPoint(touch.clientX, touch.clientY)

      const isPointDrop = els.some(el => el.contains(target))

      if (!isPointDrop) {
        targetID = null
      }
    },
  }

  return Object.assign(Geometry, {
    pluginName: 'geometry',
    eventProperties() {
      return {
        targetID,
        sourceID,
      }
    },
  })
}

export default GeometryPlugin
