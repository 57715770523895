import mixinInput from '/@mixins/input'
import mixinArtboards from '/@mixins/artboards'
import cloneDeep from 'lodash.clonedeep'

export default {
  props: {},
  mixins: [mixinInput, mixinArtboards],
  data() {
    return {
      components: [],
      isInEditMode: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.components = value
        this.setComponentTitles()
      },
    },
  },
  computed: {
    addLabel() {
      return `add ${this.settings.componentTitle}`
    },
  },
  methods: {
    updateComponentValue(event, index) {
      this.components[index].value = event.value
      this.emitValue(this.components)
    },
    updateComponentSetting(event, index) {
      this.components[index].settings[event.type] = event.value
      this.emitValue(this.components)
    },
    setComponentTitles() {
      this.components.forEach((component, index) => {
        component.title = `${this.settings.componentTitle} ${index + 1}`
      })
    },
    addComponent() {
      const component = cloneDeep(this.settings.componentConfiguration)
      this.components.push(component)
      this.setComponentTitles()
      this.emitValue(this.components)
    },
    toggleEditMode() {
      this.isInEditMode = !this.isInEditMode
    },
    deleteComponent(index) {
      this.components.splice(index, 1)
      this.emitValue(this.components)
    },
  },
  mounted() {},
}
