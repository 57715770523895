import mixinComponent from '/@mixins/component'
import mixinInput from '/@mixins/input'
import mixinMotorcortex from '/@mixins/motorcortex'
import mixinArtboards from '/@mixins/artboards'
import { settingTypes } from '/@shared/constants'
import { eventsGlobal } from '/@constants/events'
import _get from 'lodash.get'
import { empty } from '/@shared/utils'
import { useComponentData } from '/@composables/components/data'
import { useCommunication } from '/@composables/communication'

export default {
  mixins: [mixinInput, mixinComponent, mixinMotorcortex, mixinArtboards],

  computed: {
    prefix: {
      get() {
        return _get(this.settings, 'prefix', '')
      },
      set(value) {
        this.emitSetting(settingTypes.PREFIX, value)
      },
    },
    path: {
      get() {
        return this.value
      },
      set(value) {
        this.emitValue(value)
      },
    },
    slot: {
      get() {
        return _get(this.settings, 'slot', '')
      },
      set(value) {
        this.emitSetting(settingTypes.SLOT, value)
      },
    },
    expression: {
      get() {
        return this.settings.expression
      },
      set(value) {
        this.emitSetting(settingTypes.EXPRESSION, value)
      },
    },
    isPrefixVisible() {
      return this.hasSettings && this.settings.hasOwnProperty('prefix')
    },
    isExpressionVisible() {
      return this.hasSettings && this.settings.hasOwnProperty('expression')
    },
    isSlotVisible() {
      return this.hasSettings && this.settings.hasOwnProperty('slot')
    },
    isHeaderVisible() {
      return (this.isPrefixVisible || this.isExpressionVisible || this.isSlotVisible) && this.hasTitle
    },
    isPathValid() {
      const path = this.prefix + this.value

      if (empty(path)) {
        return true
      }

      const node = useCommunication().getTreeNodeFor(path)
      return !empty(node) && empty(node.children)
    },
    hasExpression() {
      return this.isExpressionVisible && !empty(this.expression)
    },
    isExpressionValid() {
      if (!this.hasExpression) {
        return true
      }

      return useComponentData().validateExpression(this.expression)
    },
  },
  methods: {
    onFocus() {
      this.$bus.$emit(eventsGlobal.SELECT_PATH_COMPONENT, {
        input: this.$refs.input,
        id: this.activeModels.map((m) => m.id).join('-'),
      })
    },
  },
}
