import _get from 'lodash.get'
import mixinResource from '/@mixins/resource'
import icons from '/@img/icons.svg?raw'

export default {
  mixins: [mixinResource],
  methods: {
    init() {
      this.loadIcons()
    },
    loadIcons() {
      let div = document.createElement('div')
      let body = document.body
      div.style.cssText =
        'border: 0; clip: rect(0 0 0 0); height: 0; overflow: hidden; padding: 0; position: absolute; width: 0;'
      div.innerHTML = icons
      body.insertBefore(div, body.childNodes[0])
    },
  },
  mounted() {
    this.init()
  },
}
