import Vue from 'vue'

// arboard components
import vgbStatusBar from '/@components/general/status-bar/index.vue'
import vgbIcon from '/@components/general/v-icon/index.vue'
import vgbLoader from '/@components/general/v-loader/index.vue'
import vProgress from '/@components/general/v-progress/index.vue'

// inspector components
import vPanel from '/@views/admin/artboards/inspector/panel/index.vue'
import vColorPicker from '/@components/inputs/v-color-picker/index.vue'
import vInput from '/@components/inputs/v-input/index.vue'
import vTextarea from '/@components/inputs/v-textarea/index.vue'
import vNumber from '/@components/inputs/v-number/index.vue'
import vRadio from '/@components/inputs/v-radio/index.vue'
import vCheckBox from '/@components/inputs/v-checkbox/index.vue'
import vFilter from '/@components/inputs/v-filter/index.vue'
import vPath from '/@components/inputs/v-path/index.vue'
import vList from '/@components/inputs/v-list/index.vue'
import vResource from '/@components/inputs/v-resource/index.vue'
import vPair from '/@components/inputs/v-pair/index.vue'
import vBreak from '/@components/inputs/v-break/index.vue'
import vSelect from '/@components/inputs/v-select/index.vue'
import vPathColor from '/@components/inputs/v-path-color/index.vue'
import vSwitch from '/@components/inputs/v-switch/index.vue'
import vRange from '/@components/inputs/Range.vue'

Vue.component('vgb-geometry', () => import('/@components/artboard/v-geometry/index.vue'))
Vue.component('vgb-point', () => import('/@components/artboard/v-geometry/v-point/index.vue'))
Vue.component('vgb-program', () => import('/@components/artboard/v-program/index.vue'))
Vue.component('vgb-light', () => import('/@components/artboard/v-light/index.vue'))
Vue.component('vgb-popover', () => import('/@components/artboard/v-popover/index.vue'))
Vue.component('vgb-input', () => import('/@components/artboard/v-input/index.vue'))
Vue.component('vgb-label', () => import('/@components/artboard/v-label/index.vue'))
Vue.component('vgb-switch', () => import('/@components/artboard/v-switch/index.vue'))
Vue.component('vgb-output', () => import('/@components/artboard/v-output/index.vue'))
Vue.component('vgb-3d', () => import('/@components/artboard/v-3d/index.vue'))
Vue.component('vgb-colorpicker', () => import('/@components/artboard/v-colorpicker/index.vue'))
Vue.component('vgb-dropdown', () => import('/@components/artboard/v-dropdown/index.vue'))

Vue.component('vgb-miniplot', () => import('/@components/artboard/v-miniplot/index.vue'))
Vue.component('vgb-dataplot', () => import('/@components/artboard/v-dataplot/index.vue'))
Vue.component('vgb-divider', () => import('/@components/artboard/v-divider/index.vue'))
Vue.component('vgb-range', () => import('/@components/artboard/v-range/index.vue'))
Vue.component('vgb-filelist', () => import('/@components/artboard/v-filelist/index.vue'))
Vue.component('vgb-meter', () => import('/@components/artboard/v-meter/index.vue'))
Vue.component('vgb-joystick', () => import('/@components/artboard/v-joystick/index.vue'))
Vue.component('vgb-scale', () => import('/@components/artboard/partials/v-scale/index.vue'))
Vue.component('vgb-camera', () => import('/@components/artboard/v-camera/index.vue'))
Vue.component('vgb-button', () => import('/@components/artboard/v-button/index.vue'))
Vue.component('vgb-image', () => import('/@components/artboard/v-image/index.vue'))
Vue.component('vgb-html', () => import('/@components/artboard/v-html/index.vue'))
Vue.component('vgb-error', () => import('/@components/artboard/partials/v-error/index.vue'))
Vue.component('vgb-preload', () => import('/@components/artboard/partials/v-preload/index.vue'))
Vue.component('vgb-promise-button', () => import('/@components/general/promise-button/index.vue'))

Vue.component('v-load-button', () => import('/@components/general/load-button/index.vue'))

Vue.component('vgb-status-bar', vgbStatusBar)
Vue.component('v-icon', vgbIcon)
Vue.component('v-loader', vgbLoader)

Vue.component('v-panel', vPanel)
Vue.component('v-color-picker', vColorPicker)
Vue.component('v-input', vInput)
Vue.component('v-radio', vRadio)
Vue.component('v-checkbox', vCheckBox)
Vue.component('v-textarea', vTextarea)
Vue.component('v-path', vPath)
Vue.component('v-list', vList)
Vue.component('v-break', vBreak)
Vue.component('v-select', vSelect)
Vue.component('v-filter', vFilter)
Vue.component('v-resource', vResource)
Vue.component('v-path-color', vPathColor)
Vue.component('v-pair', vPair)
Vue.component('v-number', vNumber)
Vue.component('v-switch', vSwitch)
Vue.component('v-progress', vProgress)
Vue.component('v-range', vRange)
