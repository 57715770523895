import Sortable from 'sortablejs'
import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'
import { nextTick } from 'vue'
import { waitForDomElement } from '/@shared/utils';

let lastTarget
let sourceID = null
let targetID = null
let els = []

function ScaledApplicationPlugin() {
  function ScaledApplication(sortable) {
    this.defaults = {
      forceFallback: true,
      fallbackOnBody: true,
    }
  }

  ScaledApplication.prototype = {
    dragStart(event) {
      const applicationScale = vuexStore.getters[types.SETTINGS_ATTRIBUTE]('application.scale')

      if (applicationScale === 1) {
        return
      }

      // the clone is not available instantly so we need to wait for it.
      waitForDomElement('body > li.sortable-drag').then((clone) => {
        const child = clone.firstChild
        const left = parseInt(clone.style.left)
        const top = parseInt(clone.style.top)
        const corrLeft = (left - left * applicationScale) * -1
        const corrTop = (top - top * applicationScale) * -1
        child.style.transformOrigin = '0 0'
        child.style.transform = `translate(${corrLeft}px, ${corrTop}px) scale(${applicationScale})`
      })
    },
  }

  return Object.assign(ScaledApplication, {
    pluginName: 'scaledApplication',
  })
}

export default ScaledApplicationPlugin
