import mixinInput from '/@mixins/input'

export default {
  mixins: [mixinInput],
  computed: {
    valueLocal: {
      get() {
        return this.value
      },
      set(value) {
        this.emitValue(value)
      },
    },
  },
}
