import { fsType } from '/@/shared/constants'
import mixinResource from '/@mixins/resource'
import { empty } from '/@/shared/utils'

export default {
  mixins: [mixinResource],
  props: {
    value: {
      type: Object,
      required: false,
    },
    target: {
      type: String,
      default: fsType.FILE,
    },
  },
  data() {
    return {
      errorMessage: '',
      directories: [],
      files: [],
      breadCrumb: [],
      activeDirectory: null,
      selectedDirectory: null,
      isLoading: false,
    }
  },
  computed: {
    isInErrorState() {
      return !empty(this.errorMessage)
    },
    areDirectoriesSelectable() {
      return this.target === fsType.DIRECTORY
    },
    areFilesSelectable() {
      return this.target === fsType.FILE
    },
    isFooterVisible() {
      return this.areDirectoriesSelectable && !empty(this.selectedDirectory)
    },
  },
  methods: {
    async init() {
      this.getDirectory(this.gridDirname)
    },
    async getDirectory(path) {
      this.isLoading = true
      this.selectedDirectory = null

      try {
        const response = await this.getResourceFromPath(path)
        const data = response.data.data

        this.listDirectories(data)
        this.listFiles(data)
        this.setActiveDirectory(data)
        this.populateBreadCrumb(data)
      } catch (e) {
        this.errorMessage = 'Error retrieving directory.'
      } finally {
        this.isLoading = false
      }
    },
    listDirectories(data) {
      this.directories = data.directories
    },
    listFiles(data) {
      this.files = data.files
    },
    populateBreadCrumb(data) {
      // const root = { name: 'root', path: '/' }
      this.breadCrumb = data.parents.concat([this.activeDirectory])
    },
    setActiveDirectory(data) {
      this.activeDirectory = data
    },
    followDirectory(directory) {
      this.selectedDirectory = null
      this.getDirectory(directory.path)
    },
    activateDirectory(directory) {
      if (this.areDirectoriesSelectable) {
        return (this.selectedDirectory = directory)
      }

      this.followDirectory(directory)
    },
    selectFile(file) {
      if (this.areFilesSelectable) {
        file.path = this.getSelectedFilePath(file)
        this.emit(file)
      }
    },
    selectDirectory() {
      if (this.areDirectoriesSelectable) {
        this.selectedDirectory.path = this.getSelectedDirectoryPath(this.selectedDirectory)
        this.emit(this.selectedDirectory)
      }
    },
    getSelectedFilePath(file) {
      if (this.activeDirectory.path === this.gridDirname) {
        return file.name
      }

      return (this.activeDirectory.path + file.name).replace(this.gridDirname, '')
    },
    getSelectedDirectoryPath(directory) {
      return directory.path.replace(this.gridDirname, '')
    },
    selectBreadCrumb(crum) {
      this.getDirectory(crum.path)
    },
    emit(item) {
      this.$emit('select', item.path)
    },
    isCrumActive(crum) {
      return crum.hash === this.activeDirectory.hash
    },
    isDirectorySelected(directory) {
      return !empty(this.selectedDirectory) && this.selectedDirectory.hash === directory.hash
    },
  },
  mounted() {
    this.init()
  },
}
