import mixinInput from '/@mixins/input'
import mixinArtboards from '/@mixins/artboards'

export default {
  props: {
    placeholder: {
      default: '',
      type: String,
    },
    settings: {
      type: Object,
    },
    value: {
      type: String,
    },
  },
  mixins: [mixinInput, mixinArtboards],
  data() {
    return {
      isPairing: false,
      modelsActive: [],
    }
  },
  computed: {
    isPaired() {
      return this.value !== ''
    },
    labels() {
      return this.settings.labels
    },
    message() {
      if (this.isPaired) {
        return this.labels.paired
      } else if (this.isPairing) {
        return this.labels.pairing
      } else {
        return this.labels.default
      }
    },
    label() {
      return this.isPaired ? 'unpair' : 'pair'
    },
    labelPaired() {
      return this.settings.labelPaired
    },
    isPairedComponentAvailable() {
      return this.allComponents //
        .pluck('id')
        .contains(this.value)
    },
  },
  methods: {
    togglePair() {
      if (this.isPaired) {
        this.unPair()
      } else {
        this.pair()
      }
    },
    pair() {
      this.isPairing = true
      this.modelsActive = [...this.artboards.models.active]
      document.addEventListener('mousedown', this.mouseDownListener)
    },
    unPair() {
      this.emitValue('')
      this.$flits.success('component unpaired')
    },
    async mouseDownListener(event) {
      await this.reActivateComponent()
      const targetModel = this.getModel(event)
      const currentModel = this.activeModels[0]

      if (targetModel) {
        this.updateComponent(currentModel, this.settings.modelAttribute, targetModel.id)
        // this.pairReciprocalBinding(currentModel, targetModel)
        this.$flits.success('component paired')
      }

      document.removeEventListener('mousedown', this.mouseDownListener)
      this.isPairing = false
    },
    reActivateComponent() {
      this.modelActivate(this.modelsActive)
      return this.$nextTick()
    },
    updateComponent(model, key, value) {
      model[key] = value
    },
    getModel(event) {
      let result = null
      const component = event.target.closest('.component-wrap')

      if (component) {
        const componentID = component.getAttribute('id').slice(9)
        const componentModel = this.findComponent(componentID)
        const isValidComponentModel = this.settings.validType === componentModel.type

        if (isValidComponentModel) {
          result = componentModel
        }
      }

      return result
    },
    checkPairedComponentPresence() {
      if (this.isPaired && !this.isPairedComponentAvailable) {
        this.unPair()
      }
    },
  },
  mounted() {
    this.checkPairedComponentPresence()
  },
}
