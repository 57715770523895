import mixinMotorcortex from '/@mixins/motorcortex'
import mixinResource from '/@mixins/resource'
import mixinSettings from '/@mixins/settings'
import { eventsGlobal } from '/@constants/events'
import { empty } from '/@shared/utils'
import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'
import ErrorSubscription from '/@plugins/communication/subscriptions/errors'
import SubscriptionData from '/@/plugins/communication/subscriptions/data'

export default {
  mixins: [mixinMotorcortex, mixinResource, mixinSettings],
  data: function () {
    return {
      errorDescription: '',
      flaggedErrors: [],
      errorList: [],
    }
  },
  computed: {
    ...mapStoreGetters(storeTypes.SETTINGS, ['error.paths.subscription', 'error.frequencyDivider']),
    ...mapStoreGetters(storeTypes.MOTORCORTEX, 'connection.open'),
    isErrorPresent() {
      return this.errorList.length > 0
    },
    isErrorDescriptionVisible() {
      return !empty(this.errorDescription)
    },
  },
  watch: {
    connectionOpen: {
      immediate: true,
      handler(isOpen) {
        if (isOpen) {
          this.subscribe()
        }
      },
    },
  },
  methods: {
    init() {
      this.bindGlobalEvents()
    },
    bindGlobalEvents() {
      this.$bus.$on(eventsGlobal.ERROR_WINDOW_TOGGLED, () => {
        this.deselectError()
      })
    },
    subscribe() {
      this.reset()

      const subscriptionData = new SubscriptionData(
        this.$communication,
        [this.errorPathsSubscription],
        'grid_errors',
        this.errorFrequencyDivider || 1000,
      )

      this.subscription = new ErrorSubscription(this.$communication, this.callback, subscriptionData)
    },
    callback(errors) {
      this.errorList = errors
      this.$emit('errors', this.errorList)
      errors.forEach((error) => this.errorAdd(error))
    },
    errorAdd(error) {
      const errorID = this.getUniqueErrorID(error)

      if (!this.flaggedErrors.includes(errorID)) {
        this.flaggedErrors.push(errorID)

        if (error.errorLevel >= 3) {
          this.$flits.error(error.message)
        } else {
          this.$flits.info(error.message)
        }
      }
    },
    getUniqueErrorID(error) {
      const subsystem = error.subsystem << 32
      return error.errorNumber + subsystem + error.timestamp
    },
    errorsAcknowledge() {
      return this.$communication
        .setParameter(this.settings.error.paths.acknowledge, 1)
        .then(() => {
          this.$emit('errors-close')
        })
        .catch((e) => {
          throw e
        })
    },
    async selectError(code) {
      const definitionFolder = this.settings.error.definition.folder

      if (empty(definitionFolder)) {
        return
      }

      try {
        const path = `${definitionFolder}error${code}.html`
        const response = await this.downloadResourceFromPath(path)
        this.errorDescription = response.data
      } catch (e) {
        this.errorDescription = 'unknown'
      }
    },
    deselectError() {
      this.errorDescription = ''
    },
    reset() {
      this.flaggedErrors = []
      this.deselectError()
      this.$emit('errors', this.errorList)

      if (this.subscription) {
        this.subscription.reset()
      }
    },
  },
  mounted() {
    this.init()
  },
}
