import logs from './logs/index.vue'
import errors from './errors/index.vue'
import status from './status/index.vue'
import mixinArtboard from '/@mixins/artboards'
import { eventsGlobal } from '/@constants/events'
import _throttle from 'lodash/throttle'
import collect from 'collect.js'
import { empty } from '/@shared/utils'
import { useCommunication } from '/@composables/communication'

const MIN_WIDTH = 300
const START_WIDTH = 720

export default {
  mixins: [mixinArtboard],
  components: {
    'vgb-logs': logs,
    'vgb-errors': errors,
    'vgb-status': status,
  },
  data() {
    return {
      areLogsAndErrorsVisible: false,
      errorLevel: null,
      mouseStartPosition: {
        x: 0,
        y: 0,
      },
      widthOnStartDrag: START_WIDTH,
      width: START_WIDTH,
      isDragged: false,
    }
  },
  computed: {
    styleObject() {
      return this.isDragged //
        ? { width: `${this.width}px` }
        : {}
    },
    isGuiListButtonVisible() {
      return IS_DEPLOYED
    },
  },
  methods: {
    init() {
      this.dragMoveFunction = _throttle(this.dragMoveListener)
      this.bindEvents()
    },
    bindEvents() {
      this.$bus.$on(eventsGlobal.MESSAGE_CLICKED, () => {
        this.areLogsAndErrorsVisible = true
      })
    },
    toggleLogsAndErrors() {
      this.areLogsAndErrorsVisible = !this.areLogsAndErrorsVisible
    },
    setErrorLevel(errorList) {
      this.errorLevel = !empty(errorList) //
        ? collect(errorList).max('errorLevel')
        : 0
    },
    dragDownListener(event) {
      this.mouseStartPosition.x = this.getEvent(event).clientX
      this.mouseStartPosition.y = this.getEvent(event).clientY
      this.widthOnStartDrag = this.width
      this.isDragged = true

      window.addEventListener('mouseup', this.dragUpListener)
      window.addEventListener('touchend', this.dragUpListener)
      window.addEventListener('mousemove', this.dragMoveFunction)
      window.addEventListener('touchmove', this.dragMoveFunction)
    },
    dragUpListener() {
      window.removeEventListener('mouseup', this.dragUpListener)
      window.removeEventListener('touchend', this.dragUpListener)
      window.removeEventListener('mousemove', this.dragMoveFunction)
      window.removeEventListener('touchmove', this.dragMoveFunction)
    },
    dragMoveListener(event) {
      this.width = this.limit(this.widthOnStartDrag + this.deltaX(event))
    },
    limit(value) {
      return Math.max(MIN_WIDTH, Math.min(value, 700))
    },
    deltaX(event) {
      if (event.touches) {
        event = event.touches[0]
      }

      return this.mouseStartPosition.x - this.getEvent(event).clientX
    },
    getEvent(event) {
      if (event.touches) {
        event = event.touches[0]
      }

      return event
    },
    logout() {
       useCommunication().closeConnection().then(() => {
        window.location.replace('/list')
       })
    }
  },
  mounted() {
    this.init()
  },
}
