import mixinInput from '/@mixins/input'

import vgbInput from '../v-input/index.vue'

export default {
  components: {
    'vgb-input': vgbInput,
  },
  mixins: [mixinInput],
  methods: {
    changeSetting(event, type) {
      this.$emit('setting', {
        attribute: event.type,
        value: event.value,
        type,
      })
    },
  },
}
