export default {
  props: {
    title: {
      type: String,
      default: 'panel',
    },
    expanded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isExpanded: true,
    }
  },
  watch: {
    expanded() {
      this.isExpanded = this.expanded
    },
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded
    },
  },
  mounted() {
    this.isExpanded = this.expanded
  },
}
