import _cloneDeep from 'lodash.clonedeep'
import { undoMutations, types } from '/@vuex/types'
import _get from 'lodash.get'
import { arraysContainSameValues, arrayWrap } from '../shared/utils'
import GridImporter from '/@importers/grid'

export default {
  install(Vue, { store }) {
    const pluginData = {
      index: -1,
      history: [],
    }

    let prevMutation = null
    let snapshotTimeout = null
    let isInitialGridLoaded = false

    store.subscribe((mutation) => {
      if (!undoMutations.includes(mutation.type)) {
        return
      }

      if (mutation.type === types.ARTBOARDS_LOAD && isInitialGridLoaded) {
        return
      }

      if (mutation.type === types.MODEL_UPDATE && mutation.payload.model.isBooting) {
        return
      }

      if (shouldCancelPrevMutation(mutation)) {
        clearTimeout(snapshotTimeout)
      }

      snapshotTimeout = setTimeout(() => {
        takeSnapshot()
      }, 150)

      prevMutation = mutation

      if (mutation.type === types.ARTBOARDS_LOAD) {
        isInitialGridLoaded = true
      }
    })

    const shouldCancelPrevMutation = (mutation) => {
      if (!prevMutation) {
        return false
      }

      if (mutation.type !== prevMutation.type) {
        return false
      }

      if (mutation.type !== types.MODEL_UPDATE) {
        return false
      }

      if (JSON.stringify(prevMutation.payload.attributes) !== JSON.stringify(mutation.payload.attributes)) {
        return false
      }

      const modelIds = arrayWrap(mutation.payload.model).map((m) => m.id)
      const prevModelIds = arrayWrap(prevMutation.payload.model).map((m) => m.id)

      if (!arraysContainSameValues(modelIds, prevModelIds)) {
        return false
      }

      return true
    }

    const takeSnapshot = () => {
      pluginData.history = pluginData.history.slice(0, pluginData.index + 1)
      pluginData.history.push(JSON.stringify(store.state))
      pluginData.index++

      if (pluginData.history.length > 50) {
        pluginData.history.shift()
        pluginData.index = pluginData.history.length - 1
      }
    }

    Vue.mixin({
      data: () => {
        return {
          pluginData,
          manualSaveTimeout: null,
        }
      },
      computed: {
        index() {
          return this.pluginData.index
        },
        history() {
          return this.pluginData.history
        },
        hasHistory() {
          return this.history.length > 1
        },
        canRedo() {
          return this.hasHistory && this.index < this.history.length - 1
        },
        canUndo() {
          return this.hasHistory && this.index > 0
        },
      },
      methods: {
        undo() {
          if (this.canUndo) {
            this.decreaseIndex()
            this.replaceState()
          }
        },
        redo() {
          if (this.canRedo) {
            this.increaseIndex()
            this.replaceState()
          }
        },
        replaceState() {
          const data = JSON.parse(this.history[this.index])
          const importer = new GridImporter()
          importer.addModelsToArtboard(data)

          store.dispatch(types.LOAD_GRID, data)
          clearTimeout(this.manualSaveTimeout)
          this.manualSaveTimeout = setTimeout(store.commit(types.GRID_MANUAL_SAVE), 1000)
        },
        increaseIndex() {
          this.pluginData.index++
        },
        decreaseIndex() {
          this.pluginData.index--
        },
      },
    })
  },
}
