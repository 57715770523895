import mixinInput from '/@mixins/input'
import vgbPath from '../v-path/index.vue'
import vgbColorPicker from '../v-color-picker/index.vue'

export default {
  mixins: [mixinInput],
  data() {
    return {}
  },
  components: {
    'vgb-path': vgbPath,
    'vgb-color-picker': vgbColorPicker,
  },
  methods: {
    pathValueChanged(event) {
      this.$emit('change', event)
    },
    pathSettingChanged(event) {
      this.$emit('setting', event)
    },
    colorValueChanged(event) {
      this.$emit('setting', {
        attribute: event.type,
        value: event.value,
        type: 'color',
      })
    },
  },
}
