export default {
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    enableOverlayClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      selector: '#dialog',
    }
  },
  watch: {
    show() {
      this.showHandler(this.show)
    },
  },
  methods: {
    showHandler(show) {
      if (show) {
        this.open()
        document.body.classList.add('modal-open')
      } else {
        this.close()
        document.body.classList.remove('modal-open')
      }
    },
    overlayClickListener() {
      if (this.enableOverlayClose) {
        this.close()
      }
    },
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    closeModal() {
      this.show = false
    },
    keyPressed(event) {
      if (event.keyCode === 27) {
        this.close()
      }
    },
  },
  mounted() {
    document.addEventListener('keyup', this.keyPressed)

    this.$nextTick(() => {
      this.$dialog.register(this)
    })
  },
  beforeDestroy() {
    document.body.classList.remove('modal-open')
    document.removeEventListener('keyup', this.keyPressed)
    this.$dialog.deregister(this)
  },
}
