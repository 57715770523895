import _Vue from 'vue'
import vDialog from './index.vue'
import { EventEmitter } from 'events'

export const dialogEvents = {
  AFTER_OPEN: 'after_open',
  AFTER_CLOSE: 'after_close',
}

class DialogManager extends EventEmitter {
  constructor() {
    super()
    this.dialogs = new Map()
  }

  register(dialog) {
    const name = dialog.name
    if (this.getDialog(name)) {
      throw new Error(`Dialog names must be unique, name: "${name}" has already been registered.`)
    }

    this.dialogs.set(name, dialog)
  }

  deregister(dialog) {
    this.dialogs.delete(dialog.name)
  }

  open(name) {
    this.getDialog(name).showHandler(true)
    this.emit(dialogEvents.AFTER_OPEN, {
      name,
    })
  }

  close(name) {
    this.getDialog(name).showHandler(false)
    this.emit(dialogEvents.AFTER_CLOSE, {
      name,
    })
  }

  toggle(name) {
    const dialog = this.getDialog(name)
    dialog.showHandler(!dialog.show)
  }

  closeModal(name) {
    this.getDialog(name).closeModal()
  }

  getDialog(name) {
    return this.dialogs.get(name)
  }
}

export default {
  install(Vue, plugin = '$dialog') {
    const dialogManager = new DialogManager()

    Vue.prototype[plugin] = {
      register(dialog) {
        dialogManager.register(dialog)
      },
      deregister(dialog) {
        dialogManager.deregister(dialog)
      },
      open(name) {
        dialogManager.open(name)
      },
      close(name) {
        dialogManager.close(name)
      },
      toggle(name) {
        dialogManager.toggle(name)
      },
      closeModal(name) {
        dialogManager.closeModal(name)
      },
      on(event, listener) {
        dialogManager.on(event, listener)
      },
    }

    _Vue.component('vgb-dialog', vDialog)
  },
}
