import mixinInput from '/@mixins/input'
import Point from './helpers/point'
import _get from 'lodash.get'
import _throttle from 'lodash.throttle'
import { extractTouch, constrain, addDragClassToBody, removeDragClassFromBody } from '/@/shared/utils'

export default {
  props: {
    debounce: {
      default: 0,
      type: Number,
    },
    placeholder: {
      default: '',
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previousPosition: new Point(0, 0),
      startPosition: new Point(0, 0),
      startValue: 0,
      previousValue: this.value,
      debouceTimeout: 0,
      isDebounceDisabled: false,
      isSelected: false,
      isDragging: false,
    }
  },
  mixins: [mixinInput],
  computed: {
    inputSettings() {
      const result = {}

      if (this.hasSettings) {
        const attributes = ['min', 'max', 'step']

        attributes.forEach((attr) => {
          if (this.settings.hasOwnProperty(attr)) {
            result[attr] = this.settings[attr]
          }
        })
      }

      return result
    },
    isDebounced() {
      return this.debounce !== 0 && !this.isDebounceDisabled
    },
    suffix() {
      return _get(this.settings, 'suffix', null)
    },
    step() {
      return _get(this.settings, 'step', 1)
    },
    dragElement() {
      return this.$refs.drag
    },
    min() {
      return _get(this.settings, 'min', null)
    },
    max() {
      return _get(this.settings, 'max', null)
    },
    hasPercentage() {
      return this.min !== null && this.max !== null
    },
    percentage() {
      return ((this.inputValue - this.min) / (this.max - this.min)) * 100
    },
    decimals() {
      return _get(this.settings, 'decimals', -1)
    },
    isRounded() {
      return this.decimals > -1
    },
    inputValue: {
      get() {
        if (this.isSelected || this.isDragging || !this.isRounded) {
          return this.value
        }

        return parseFloat(this.value.toFixed(this.decimals))
      },
      set(value) {
        if (Number.isNaN(value)) {
          value = this.previousValue
        }

        value = constrain(value, this.min, this.max)
        this.previousValue = value

        if (this.isDebounced) {
          this.debounceEmitValue(value)
        } else {
          this.emitValue(value)
        }
      },
    },
  },
  methods: {
    debounceEmitValue(value) {
      clearTimeout(this.debouceTimeout)

      this.debouceTimeout = setTimeout(() => {
        this.emitValue(value)
      }, this.debounce)
    },
    bindEvents() {
      this.mouseMoveThrottled = _throttle(this.moveListener, 100)
      this.dragElement.addEventListener('mousedown', this.downListener)
      this.dragElement.addEventListener('touchstart', this.downListener, { passive: true })
    },
    unbindEvents() {
      this.dragElement.removeEventListener('mousedown', this.downListener)
      this.dragElement.removeEventListener('touchstart', this.downListener, { passive: true })
    },
    downListener(event) {
      event = extractTouch(event)

      this.isDebounceDisabled = true
      this.startPosition = new Point(event.clientX, event.clientY)
      this.startValue = this.value
      this.isDragging = true

      window.addEventListener('mousemove', this.mouseMoveThrottled, false)
      window.addEventListener('mouseup', this.upListener, false)
      window.addEventListener('touchmove', this.mouseMoveThrottled, { passive: true })
      window.addEventListener('touchend', this.upListener, false)

      addDragClassToBody()
    },
    moveListener(event) {
      event = extractTouch(event)

      const newPosition = new Point(event.clientX, event.clientY)
      const diffPosition = newPosition.diff(this.startPosition)
      const yPosFactor = Math.max(1 - Math.abs(newPosition.y - this.startPosition.y) / 300, 0.1)

      let addValue = diffPosition.x * yPosFactor * this.step
      addValue = addValue - (addValue % this.step)
      const newValue = this.startValue + addValue

      this.inputValue = newValue
    },
    upListener() {
      this.isDebounceDisabled = false
      this.isDragging = false

      window.removeEventListener('mousemove', this.mouseMoveThrottled, false)
      window.removeEventListener('mouseup', this.upListener, false)
      window.removeEventListener('touchmove', this.mouseMoveThrottled, false)
      window.removeEventListener('touchend', this.upListener, false)
      removeDragClassFromBody()
    },
    focus() {
      this.$refs.input.focus()
    },
    blur() {
      this.$refs.input.blur()
    },
    toggleIsSelected() {
      this.isSelected = !this.isSelected
    },
    keyUpListener(event) {
      if (event.code === 'Tab') {
        this.$nextTick(() => {
          this.$refs.input.select()
        })
      }
    },
  },
  mounted() {
    if (!this.readonly) {
      this.bindEvents()
    }
  },
  beforeDestroy() {
    this.unbindEvents()
  },
}
