import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'

export default {
  install(Vue) {
    Object.defineProperty(Vue.prototype, '$can', {
      value: permission => {
        return vuexStore.getters[types.SETTINGS_STATE].permissions.includes(permission)
      },
    })
  },
}
