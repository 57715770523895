import '/@shared/polyfills'
import '/@shared/uikit'
import '/@css/style.less'
import '/@shared/plugins'
import '/@shared/components'
import '/@shared/filters'
import '/@directives/can'
import '/@shared/sortable/plugins'

import session from '/@shared/session'
import Vue from 'vue'
import Logger from '/@shared/error-logger'
import { empty } from '/@shared/utils'
import http from './shared/http'
import vuexStore from '/@vuex/store'
import app from '/@views/app/index.vue'
import VuePortal from '@linusborg/vue-simple-portal'
import vDialog from '/@components/general/v-dialog'
import router from '/@shared/router'
import store from 'store'

const bus = new Vue()

class Application {
  get hasDefaultGui() {
    return !empty(store.get('vectioneer.gui.default'))
  }

  get isOnScreensRoute() {
    return window.location.href.includes('screens')
  }

  constructor() {
    if (IS_DEPLOYED) {
      this.initDeployedGui()
    } else {
      this.mountVueInstance()
      // this.initLogger()
    }
  }

  initDeployedGui() {
    if (!this.hasDefaultGui) {
      return this.redirectToGuiList()
    }

    this.initSession()
    this.mountVueInstance()
  }

  mountVueInstance() {
    Vue.use(VuePortal, { name: 'portal' })
    Vue.use(vDialog)

    Vue.prototype.$http = http
    Vue.prototype.$bus = bus

    new Vue({
      router,
      store: vuexStore,
      el: '#app',
      template: '<app/>',
      components: {
        app,
      },
    })
  }

  initSession() {
    const defaultGui = store.get('vectioneer.gui.default')
    const { project, grid } = defaultGui

    // decodeURIComponent: Ensure that the path is properly decoded
    session.set('project', decodeURIComponent(project.path))
    session.set('grid', decodeURIComponent(grid.path))
  }

  initLogger() {
    this.logger = new Logger()

    Vue.config.errorHandler = (err) => {
      this.logger.addErrorRecord(err)

      if (DEBUG) {
        throw err
      }
    }
  }

  redirectToGuiList() {
    window.location.replace('/list/')
  }
}

export default new Application()
