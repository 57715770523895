import Vue from 'vue'
import communication from '/@plugins/communication/communication'
import flits from '/@plugins/flits'
import can from '/@plugins/can'
import router from '/@shared/router'
import store from '/@vuex/store'
import undo from '/@plugins/undo'

Vue.use(communication, {
  store,
  router,
})

Vue.use(undo, {
  store: store,
})

Vue.use(flits)
Vue.use(can)
